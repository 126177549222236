import { FC, useState, useEffect, useRef, CSSProperties } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { EffectFade, Navigation, Keyboard, Mousewheel } from "swiper/modules";
import ClipLoader from "react-spinners/ClipLoader";

import ContentSlider from "../../components/ContentSlider";
import DescriptionSection from "../../components/DescriptionSection";
import CommentSection from "../../components/CommentSection";

import { fetchProductsWithDetails } from "../../services/apiClient";
import { ProductDataArray } from "../../types/common";

import styles from "./MainScreen.module.css";

const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "white",
};

const MainScreen: FC = () => {
  const [data, setData] = useState<ProductDataArray>([]);
  const [activeIndex, setActiveIndex] = useState(0); // Состояние для активного слайда

  const swiperRef = useRef<any>(null);
  // Используем рефы для кнопок
  const prevButtonRef = useRef<HTMLDivElement>(null);
  const nextButtonRef = useRef<HTMLDivElement>(null);

  const [error, setError] = useState(null);

  useEffect(() => {
    // Получаем продукты при загрузке компонента
    const getProducts = async () => {
      try {
        const data = await fetchProductsWithDetails(0, 10, "name", "ASC");
        setData(data as ProductDataArray);
      } catch (err) {
        if (err instanceof Error) {
          // @ts-ignore
          setError(err.message); // Приведение типа
        } else {
          // @ts-ignore
          setError("Неизвестная ошибка"); // Обработка других типов ошибок
        }
      }
    };
    setTimeout(() => {
      getProducts();
    }, 1);
  }, []);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.update();
    }
  }, [activeIndex]);

  const handleSwiperInit = () => {
    const swiperWrapper = document.querySelector(
      ".swiper-wrapper"
    ) as HTMLElement;
    if (swiperWrapper) {
      swiperWrapper.style.height = "100vh";
    }
    const swiper = document.querySelector(".swiper") as HTMLElement;
    if (swiperWrapper) {
      swiper.style.width = "100%";
    }
  };

  const handleSlideChange = (swiper: any) => setActiveIndex(swiper.realIndex);

  if (error) {
    return <div>Ошибка: {error}</div>;
  }

  if (!data.length)
    return (
      <div className={styles["site-wrapper"]}>
        <ClipLoader
          color="#ffffff"
          cssOverride={override}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );

  return (
    <div className={styles["content-section"]}>
      <div className={styles["content-section__left"]}>
        <Swiper
          ref={swiperRef}
          loop={true}
          direction="vertical"
          spaceBetween={0}
          slidesPerView={1}
          effect="slide"
          // fadeEffect={{ crossFade: true }}
          speed={800}
          modules={[EffectFade, Navigation, Keyboard, Mousewheel]}
          pagination={{ clickable: true }}
          navigation={{
            nextEl: "[data-swiper-button-next]",
            prevEl: "[data-swiper-button-prev]",
          }}
          onSwiper={handleSwiperInit}
          onSlideChange={handleSlideChange}
          mousewheel={true} // https://idangero.us/swiper/api/#mousewheel
          scrollbar={{ draggable: true }}
          keyboard={{ enabled: true, onlyInViewport: false }}
        >
          {data &&
            data.map((item, index) => (
              <SwiperSlide key={index}>
                <ContentSlider data={item} />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className={styles["swiper-navigation"]}>
          <div className={styles["swiper-navigation-buttons"]}>
            <div
              ref={prevButtonRef}
              className={styles["swiper-button-prev"]}
              data-swiper-button-prev
            ></div>
            <div
              ref={nextButtonRef}
              className={styles["swiper-button-next"]}
              data-swiper-button-next
            ></div>
          </div>
        </div>
      </div>
      <div className={styles["content-section__right"]}>
        <DescriptionSection data={data[activeIndex]} />

        <CommentSection data={data[activeIndex]} />
      </div>
    </div>
  );
};

export default MainScreen;
