import React, { FC } from "react";

import SocialEngine from "../SocialEngine";

import { ProductData } from "../../types/common";

import styles from "../../common/styles/CommonStyles.module.css";

interface ContentSliderProps {
  data: ProductData;
}

const DescriptionSection: React.FC<ContentSliderProps> = ({ data }) => {
  return (
    <div className={styles["product__details"]}>
      <div className={styles["product__author"]}>
        <span className={styles["product__author-name"]}>
          {data.author.name}
        </span>
        <button className={styles["product__follow-button"]}>Купити</button>
      </div>
      <div className={styles["product__description"]}>
        <h1>{data.author.description}</h1>
        {data.author.cameraSpecs.map((spec, index) => (
          <div key={index} className={styles["section"]}>
            {/* <h3>{spec.name}</h3> */}
            <ul className={styles["list"]}>
              {spec.details.map((detail, idx) => (
                <li key={idx}>
                  {/* <strong>{detail.label}:</strong> */}

                  <div dangerouslySetInnerHTML={{ __html: detail.value }} />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <SocialEngine />
    </div>
  );
};

export default DescriptionSection;
