import React from "react";

import styles from "../../common/styles/CommonStyles.module.css";

import noImageBackground from "../../assets/images/no-image-icon.png";

import { ProductData } from "../../types/common";
interface ContentSliderProps {
  data: ProductData;
}

const ContentSlider: React.FC<ContentSliderProps> = ({ data }) => {
  return (
    <div className={styles["content-section"]}>
      <div className={styles["content-section__left"]}>
        <div className={styles["product-card"]}>
          <div className={styles["product-card__image"]}>
            {data.product.image ? (
              <img
                src={`${process.env.REACT_APP_ASSETS_URL}${data.product.image}`}
                alt={data.product.name}
              />
            ) : (
              <img src={noImageBackground} alt={data.product.name} />
            )}
          </div>
          <div className={styles["product-card__colors"]}>
            {data.product.colors &&
              data.product.colors.map((color) => (
                <span
                  key={color}
                  className={`${styles["product-card__color"]} ${
                    styles[`product-card__color--${color}`]
                  }`}
                ></span>
              ))}
          </div>
          <div className={styles["product-card__details"]}>
            <div className={styles["product-card__name"]}>
              {data.product.name}
            </div>
            <div className={styles["product-card__rating"]}>
              <span className={styles["product-card__stars"]}>
                {"★".repeat(data.product.rating)}
              </span>
              <span className={styles["product-card__reviews"]}>
                {data.product.reviews}
              </span>
            </div>
            <div className={styles["product-card__availability"]}>
              {data.product.availability}
            </div>
            <div className={styles["product-card__price"]}>
              <span className={styles["product-card__price-old"]}>
                {data.product.priceOld}
              </span>
              <span className={styles["product-card__price-new"]}>
                {data.product.priceNew}
              </span>
            </div>
          </div>

          <div
            className={styles["product-card__button-buy"]}
            onClick={() => alert("Button clicked!!")}
          >
            Купити
          </div>
        </div>

        {/* Размытый фон */}
        <div
          className={styles["background-image"]}
          style={{
            backgroundImage: `url(${process.env.REACT_APP_ASSETS_URL}${data.product.image})`,
          }}
        ></div>
      </div>
    </div>
  );
};

export default ContentSlider;
