import React, { FC, useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faHeart,
  faComment,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./SocialEngine.module.css";

const SocialEngine: FC = () => {
  return (
    <div>
      <div className={styles["container"]}>
        <div className={styles["row"]}>
          <div className={styles["row-items"]}>
            <i className="fa-solid fa-heart icon"></i>
            <FontAwesomeIcon
              icon={faHeart}
              size="1x"
              className={styles["icon"]}
            />
            <span className={styles["text"]}>360k</span>
          </div>
          <div className={styles["row-items"]}>
            <i className="fa-solid fa-comment icon"></i>
            <FontAwesomeIcon
              icon={faComment}
              size="1x"
              className={styles["icon"]}
            />
            <span className={styles["text"]}>1609</span>
          </div>
          <div className={styles["row-items"]}>
            <i className="fa-solid fa-bookmark icon"></i>
            <FontAwesomeIcon
              icon={faBookmark}
              size="1x"
              className={styles["icon"]}
            />
            <span className={styles["text"]}>22k</span>
          </div>
        </div>

        {/* <div className="url-container">
                              <input type="text" value="https://localhost" readonly="">
                              <button onclick="copyToClipboard()">????????? ?????????</button>
                          </div> */}
      </div>
    </div>
  );
};

export default SocialEngine;
