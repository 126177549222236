import axios from "axios";

// Базовая настройка Axios
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`, // Замените на ваш базовый URL
  headers: {
    "Content-Type": "application/json",
  },
});

// Функция для получения списка продуктов с деталями
export const fetchProductsWithDetails = async (
  start = 0,
  end = 10,
  sort = "name",
  order = "ASC"
) => {
  try {
    const response = await api.get(`/products_with_details`);
    return response.data;
  } catch (error) {
    console.error("Ошибка при получении продуктов с деталями:", error);
    throw error;
  }
};

// Функция для отправки комментария на сервер
export const postComment = async (
  product_id: number,
  name: string,
  comment: string,
  ip_address: string
) => {
  try {
    const response = await api.post(`/comments`, {
      product_id,
      name,
      comment,
      ip_address,
    });
    return response.data;
  } catch (error) {
    console.error("Ошибка при отправке комментария:", error);
    throw error;
  }
};

// Получение комментариев для продукта
export const fetchProductComments = async (product_id: number) => {
  try {
    const response = await api.get(`/products/${product_id}/comments`);

    return response.data;
  } catch (error) {
    console.error("Ошибка при получении комментариев:", error);
    throw error;
  }
};

export const likeComment = async (commentId: number) => {
  try {
    const response = await api.post(`/comments/${commentId}/like`);
    return response.data;
  } catch (error) {
    throw new Error("Ошибка при добавлении лайка");
  }
};
