import React, { useState, useRef, useEffect } from "react";
import { publicIpv4 } from "public-ip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faHeart,
  faComment,
  faBookmark,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./CommentSection.module.css";

import {
  postComment,
  fetchProductComments,
  likeComment,
} from "../../services/apiClient";

interface CommentSectionProps {
  data?: any;
}

interface Comment {
  id: number;
  commentator_name: string; // Имя комментатора
  text: string; // Текст комментария
  created_at: string; // Дата создания
  likes: number; // Лайки
}

const CommentSection: React.FC<CommentSectionProps> = ({ data }) => {
  const product_id = data?.product?.id;
  const commentSectionRef = useRef<HTMLDivElement>(null);
  const [name, setName] = useState("");
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState<Comment[]>([]); // Состояние для комментариев
  const [ip_address, setIp] = useState(""); // Состояние для IP-адреса
  const [error, setError] = useState<string | null>(null); // Тип состояния теперь string | null

  // Получение IP-адреса
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const ipAddress = await publicIpv4();
        setIp(ipAddress);
      } catch (error) {
        console.error("Ошибка при получении IP-адреса:", error);
      }
    };

    fetchIp();
  }, []);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const swiperElement = commentSectionRef.current?.closest(".swiper") as any;
    if (swiperElement?.swiper) {
      swiperElement.swiper.allowTouchMove = false;
    }
  };

  const handleTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const swiperElement = commentSectionRef.current?.closest(".swiper") as any;
    if (swiperElement?.swiper) {
      swiperElement.swiper.allowTouchMove = true;
    }
  };

  // Ваша функция для обработки отправки
  const handleSubmit = async () => {
    if (!name.trim() || !comment.trim()) {
      setError("Пожалуйста, заполните все поля.");
      toast.error("Пожалуйста, заполните все поля.");
      return;
    }

    try {
      console.log("Отправляем данные:", {
        product_id,
        name,
        comment,
        ip_address,
      });

      const response = await postComment(product_id, name, comment, ip_address);

      console.log("Ответ сервера:", response);

      setError(null);
      toast.success("Комментарий успешно добавлен.");

      setName(""); // Очистка формы после успешной отправки
      setComment("");
    } catch (error: any) {
      console.error(
        "Ошибка при отправке комментария:",
        error.response ? error.response.data : error.message
      );
      setError("Ошибка при отправке комментария.");
      toast.error(
        `Ошибка при отправке комментария:\n${JSON.stringify(
          error.response ? error.response.data : error.message
        )}`
      );
    }
  };

  // Получение комментариев при загрузке компонента
  // Проверяем, лайкнул ли пользователь этот комментарий
  const hasLikedComment = (commentId: number) => {
    const likedComments = JSON.parse(
      localStorage.getItem("likedComments") || "[]"
    );
    return likedComments.includes(commentId);
  };

  // Сохраняем лайк в localStorage
  const saveLikedComment = (commentId: number) => {
    const likedComments = JSON.parse(
      localStorage.getItem("likedComments") || "[]"
    );
    likedComments.push(commentId);
    localStorage.setItem("likedComments", JSON.stringify(likedComments));
  };

  const getComments = async () => {
    try {
      const response = await fetchProductComments(product_id);
      setComments(response); // Устанавливаем полученные комментарии в состояние
    } catch (error) {
      console.error("Ошибка при получении комментариев:", error);
      toast.error("Ошибка: Не удалось загрузить комментарии.");
    }
  };

  useEffect(() => {
    getComments();
  }, [product_id]);

  const handleLike = async (commentId: number) => {
    if (hasLikedComment(commentId)) {
      // alert("Вы уже лайкнули этот комментарий!");
      toast.error("Вы уже лайкнули этот комментарий!");
      return;
    }

    try {
      await likeComment(commentId);
      saveLikedComment(commentId); // Сохраняем в localStorage, что лайк был поставлен
      await getComments();
    } catch (error) {
      console.error("Ошибка при добавлении лайка:", error);
      alert("Ошибка при добавлении лайка.");
    }
  };

  return (
    <div
      className={styles.container}
      ref={commentSectionRef}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      style={{ zIndex: 10 }} // Убедитесь, что элементы на переднем плане
    >
      <ToastContainer />
      <div className={styles.header}>
        <span>Коментарі ({comments.length})</span>
      </div>

      <ul className={styles.commentsList}>
        {comments.length > 0 ? (
          <>
            {comments.map((comment, index) => {
              return (
                <li key={comment.id} className={styles.commentItem}>
                  <div className={styles.comment}>
                    <div className={styles.commentHeader}>
                      <span>{comment?.commentator_name}</span>{" "}
                      {/* Используем имя комментатора */}
                    </div>
                    <div className={styles.commentText}>{comment?.text}</div>
                    <div className={styles.commentFooter}>
                      <span>
                        {new Date(comment?.created_at).toLocaleString()}
                      </span>
                      {/* Форматируем время */}
                      {comment.likes > 0 ? (
                        <button
                          className={styles.likes}
                          onClick={() => handleLike(comment.id)}
                        >
                          <FontAwesomeIcon
                            icon={faHeart}
                            size="1x"
                            className={styles["icon"]}
                            color={"red"}
                          />{" "}
                          {comment.likes}
                        </button>
                      ) : (
                        <button
                          className={styles.likes}
                          onClick={() => handleLike(comment.id)}
                        >
                          <FontAwesomeIcon
                            icon={faHeart}
                            size="1x"
                            className={styles["icon"]}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
          </>
        ) : (
          <div>
            <span>К этому продукту, еще нет комментариев, будь первым!</span>
          </div>
        )}
      </ul>

      <div className={styles.commentForm}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <input
            type="text"
            placeholder="Имя *"
            className={styles.input}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <button className={styles.submitButton} onClick={handleSubmit}>
            <FontAwesomeIcon
              icon={faComment}
              size="1x"
              className={styles["icon"]}
            />{" "}
            Опублікувати
          </button>
        </div>
        <input
          type="text"
          placeholder="Додати коментар... *"
          className={styles.input}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        {/* {error && <div className={styles.error}>{error}</div>} */}
      </div>
    </div>
  );
};

export default CommentSection;
